<template>
  <div>
    <v-container class="my-1">
      <v-snackbar top center v-model="snackbar">
        {{ $t("product registered successfully") }}!
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="$store.state.secondaryColor"
            text
            v-bind="attrs"
            @click="snackbar = false"
            :timeout="timeout"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-layout row wrap justify-space-between class="mb-2">
        <v-flex xs6 sm4 md4 lg4 class="mb-1">
          <v-btn
            :color="$store.state.secondaryColor"
            outlined
            small
            @click="goBack"
          >
            <v-icon small left>mdi-arrow-left</v-icon>
            <span class="caption">{{ $t("back") }}</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>

      <v-row justify="space-between" class="mt-1">
        <v-row justify="space-between" class="mb-1">
          <v-col cols="12" md="6">
            <h2 class="headline text-capitalize">
              {{ $t("add product to inventory") }}
            </h2>
            <small>*{{ $t("indicates required field") }}.</small>
          </v-col>

          <v-col cols="12" md="6" align-self="end" class="text-right">
            <v-btn
              small
              dark
              class="mr-4"
              :color="$store.state.primaryColor"
              @click="demoForm"
            >
              <v-icon small left>gamepad</v-icon>
              <span class="caption text-capitalize">Demo</span>
            </v-btn>
            <v-btn
              dark
              small
              :loading="loading"
              :color="$store.state.secondaryColor"
              @click="uploadData"
            >
              <v-icon small left>save</v-icon>
              <span class="caption text-capitalize">{{ $t("register") }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-alert>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-card class="pa-2" max-width="344">
                    <v-img :src="prodPreview" height="200px"></v-img>
                  </v-card>

                  <input
                    type="file"
                    @change="previewPicture"
                    class="caption mt-2"
                  />
                </v-col>

                <v-col cols="12" md="8">
                  <v-row dense class="caption">
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("suppliers") }}* :
                      </span>

                      <v-autocomplete
                        v-model="product.supplierID"
                        :items="suppliers"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        @change="setSupplierDetails(product.supplierID)"
                        :rules="rules.emptyField"
                      ></v-autocomplete>
                      <v-btn
                        :color="$store.state.primaryColor"
                        outlined
                        small
                        @click="newSupplier"
                      >
                        {{ $t("new supplier") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("business name") }}* :
                      </span>
                      <v-text-field
                        v-if="selectedBusiness"
                        outlined
                        dense
                        v-model="selectedBusinessName"
                        readonly
                        :rules="rules.emptyField"
                      ></v-text-field>

                      <v-select
                        v-else
                        outlined
                        dense
                        item-text="businessName"
                        item-value="id"
                        :items="ownersBusinesses"
                        v-model="product.businessID"
                        :rules="rules.emptyField"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("product barcode") }}* :
                        <span>
                          <v-btn
                            height="5"
                            small
                            text
                            class="blue--text text-lowercase text-decoration-underline"
                            @click="generateBarcodeNo"
                          >
                            {{ $t("autogenerate") }}
                          </v-btn>
                        </span></span
                      >

                      <v-text-field
                        outlined
                        dense
                        @keypress="digits"
                        v-model="product.barcode"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("product category") }} * :
                      </span>
                      <v-autocomplete
                        outlined
                        dense
                        item-text="category"
                        item-value="id"
                        :items="categories"
                        v-model="product.categoryID"
                        @change="setCategory(product.categoryID)"
                        :rules="rules.emptyField"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("product name") }}* :
                      </span>
                      <v-textarea
                        outlined
                        dense
                        auto-grow
                        rows="1"
                        v-model="product.name"
                        :rules="rules.emptyField"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("description") }}* :
                      </span>
                      <v-textarea
                        outlined
                        dense
                        auto-grow
                        rows="1"
                        v-model="product.description.about"
                        :rules="rules.emptyField"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("brand") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="product.description.brand"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("model") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="product.description.model"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium"
                        >{{ $t("type") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="product.description.type"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("size") }}* :
                      </span>
                      <v-textarea
                        outlined
                        dense
                        auto-grow
                        rows="1"
                        v-model="product.description.size"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("color") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="product.description.color"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("product origin") }}* :
                      </span>
                      <v-autocomplete
                        outlined
                        dense
                        v-model="product.origin"
                        :items="countryNameList"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("opening stock") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="product.quantity.openingStock"
                        :rules="rules.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("expiry date") }}:
                      </span>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="product.expiryDate"
                            append-icon="mdi-calendar"
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="product.expiryDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("date ordered") }}:
                      </span>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="product.date.ordered"
                            append-icon="mdi-calendar"
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="product.date.ordered"
                          @input="menu2 = false"
                          :max="product.date.delivered"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("date delivered") }}:
                      </span>
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        {{ product.date.ordered }}
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="product.date.delivered"
                            append-icon="mdi-calendar"
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <!--min to prevent entering a date before the ordered date -->
                        <v-date-picker
                          v-model="product.date.delivered"
                          @input="menu3 = false"
                          :min="product.date.ordered"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium"
                        >{{ $t("buying price") }}* :
                      </span>
                      <v-text-field
                        @keypress="digits"
                        outlined
                        dense
                        v-model.trim="product.price.buying"
                        :rules="rules.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium"
                        >{{ $t("selling price") }}* :
                      </span>
                      <v-text-field
                        @keypress="digits"
                        outlined
                        dense
                        v-model.trim="product.price.selling"
                        :rules="rules.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium"
                        >{{ $t("limit price") }}* :
                      </span>
                      <v-text-field
                        @keypress="digits"
                        outlined
                        dense
                        v-model.trim="product.price.limit"
                        :rules="rules.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="font-weight-medium"
                        >{{ $t("discount") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        @keypress="digits"
                        v-model.trim="product.price.discount"
                        :rules="rules.number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-alert>
      </v-row>
      <v-snackbar
        v-model="snackError"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not add product. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackError = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackErrorData"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not get details. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorData = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import fb from "firebase/app";
import { digits } from "@/plugins/functions";

export default {
  data: () => ({
    loading: false,
    categories: [],
    snackbar: false,
    snackError: false,
    snackErrorData: false,
    menu1: false,
    menu2: false,
    menu3: false,
    timeout: 2000,
    suppliers: [],
    product: {
      lastUpdateDate: new Date(),
      avatar: "",
      barcode: "",
      name: "",
      businessID: "",
      categoryID: "",
      quantity: {
        ordered: "",
        delivered: "",
        inStock: "",
        openingStock: "",
      },
      description: {
        about: "",
        brand: "",
        model: "",
        color: "",
        size: "",
        type: "",
      },

      date: {
        ordered: "",
        delivered: "",
      },
      price: {
        buying: "",
        selling: "",
        limit: "",
        discount: "0",
      },
      origin: "",
      expiryDate: "",
    },
    prodPreview:
      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",
    file: "",
    img: require("@/assets/demo/adapter2.jpg"),
  }),

  computed: {
    imageName() {
      return this.product.businessID + "-" + this.product.name;
    },

    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },

    countryNameList() {
      let countries = this.$store.getters.getCountries;
      let countryNames = countries.map((item) => item.name);
      return countryNames;
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
        number: [
          (v) => !!v || this.$t("this field is required"),
          (v) => !isNaN(v) || this.$t("please enter a number"),
        ],
      };
    },
  },

  created() {
    this.getCategories();
    this.getSuppliers();
  },

  methods: {
    digits,
    demoForm() {
      this.product.name = "Denim Skirt";
      this.product.categoryID = "OIJbKSQnpRghAbgR9bhY";
      this.product.barcode = "1234567891011";
      this.product.quantity.openingStock = "100";
      this.product.description.about =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";
      this.product.description.brand = "Brand A";
      this.product.description.model = "model A";
      this.product.description.color = "blue";
      this.product.description.size = "(L x W x H cm): 42 x 28 x 5";
      this.product.description.type = "Denim";
      this.product.price.buying = "1000";
      this.product.price.selling = "2750";
      this.product.price.limit = "2600";
      this.product.price.discount = "0";
      this.product.origin = "Turkey";
    },

    getCategories() {
      db.collection("productCategory")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categories.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        })
        .catch(() => {
          this.snackErrorData = false;
        });
    },

    getSuppliers() {
      this.suppliers = [];
      if (this.selectedBusiness) {
        db.collection("suppliers")
          .where("businessID", "==", this.selectedBusiness)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.suppliers.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          })
          .catch(() => {
            this.snackErrorData = false;
          });
      } else {
        db.collection("suppliers")
          .where("businessID", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.suppliers.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          })
          .catch(() => {
            this.snackErrorData = false;
          });
      }
    },

    setSupplierDetails(id) {
      //set supplier id to product details
      let supplier = this.suppliers.find((x) => x.id === id);

      this.product.supplierID = supplier.id;
    },
    setCategory(id) {
      //set category id to product details
      let category = this.categories.find((x) => x.id === id);

      this.product.categoryID = category.id;
    },
    previewPicture(event) {
      this.file = event.target.files[0];
      this.prodPreview = URL.createObjectURL(this.file);
    },

    generateBarcodeNo() {
      let barcodeNo = Math.floor(Math.random() * 10000000000000 + 1);
      this.product.barcode = String(barcodeNo);
    },

    setBusinessId() {
      if (this.selectedBusiness) {
        this.product.businessID = this.selectedBusiness;
      }
    },
    uploadData() {
      if (this.$refs.form.validate()) {
        this.product.quantity.inStock = this.product.quantity.openingStock;
        this.setBusinessId();
        this.loading = true;

        let storageRef = fb.storage().ref("stock_images/" + this.imageName);
        let uploadTask = storageRef.put(this.file);

        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            error.preventDefault();
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
              this.product.avatar = downloadLink;

              db.collection("products")
                .doc(String(this.product.barcode))
                .set(this.product)
                .then(() => {
                  this.loading = false;
                  this.snackbar = true;
                  this.prodPreview = "";
                  this.$refs.form.reset();
                  this.goBack();
                })
                .catch(() => {
                  this.snackError = false;
                });
            });
          }
        );
      }
    },
    newSupplier() {
      this.$router.push({ name: "RegisterSupplier" });
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
